<template>
  <q-page>
    <q-tabs
      v-model="showTab"
      active-color="primary"
    >
      <q-tab name="active" icon="fal fa-ticket-alt" label="Active">
        <q-badge floating>
          {{ counts.active }}
        </q-badge>
      </q-tab>
      <q-tab name="inactive" icon="fal fa-stream" label="Available">
        <q-badge floating>
          {{ counts.inactive }}
        </q-badge>
      </q-tab>
      <q-tab name="expired" icon="fal fa-trash-alt" label="Expired">
        <q-badge floating>
          {{ counts.expired }}
        </q-badge>
      </q-tab>
    </q-tabs>
    <q-list>
      <ticket v-for="ticket in filteredTickets" :key="ticket.id" v-bind="ticket" />
    </q-list>
    <q-footer class="buy-ticket-footer">
      <q-btn
        unelevated
        align="center"
        color="primary"
        icon="fas fa-ticket-alt"
        :label="$t('public_transport_ticket')"
        class="buy-ticket"
        @click="buyTicket"
      />
    </q-footer>
  </q-page>
</template>

<script>
import ticket from './ticket.vue'
import { index } from 'api/tickets'
export default {
  components: { ticket },
  data () {
    return {
      // Default for showTab is active, but in the future if there is no active tickets
      // the default should be switched to inactive/available
      showTab: 'active',
      tickets: []
    }
  },
  computed: {
    filteredTickets () {
      return this.tickets.filter(ticket => ticket.status === this.showTab)
    },
    counts () {
      let active = 0, inactive = 0, expired = 0

      this.tickets.forEach(ticket => {
        if (ticket.status === 'active') active++
        else if (ticket.status === 'inactive') inactive++
        else if (ticket.status === 'expired') expired++
      })

      return { active, inactive, expired }
    }
  },
  beforeMount () {
    index().then(res => {
      this.tickets = res.data
      if (res.data.filter(t => t.status === 'active').length < 1) this.showTab = 'inactive'
    })
  },
  methods: {
    buyTicket () {
      this.$store.commit('ondemand/clear')
      this.$router.push({ name: 'public-transport-form' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.buy-ticket-footer
  padding 10px 10px 64px 10px
  background white
.buy-ticket
  width 100%
</style>
